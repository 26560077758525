import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'


const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">   
            <div className="inner"><a onMouseOver={()=>{window.sendOwl.captureLinks();}} href="https://transactions.sendowl.com/packages/746040/B0B03C37/view">Download & Donate</a></div>
        </div>
        <div className="content">
            <div className="inner">
                <h2><strong>NEW:</strong> <a href="https://www.damianieditore.com/en-US/product/797">Michael Stipe</a> Volume 3 now out on Damiani</h2>
                <h3>OUT NOW: <a href="https://lnk.to/NoTimeForLoveLikeNow">"No Time for Love Like Now"<br/>by Michael Stipe & Big Red Machine</a></h3>
                <h5>"Drive to the Ocean" is now on  <a href="https://ffm.to/drivetotheocean">streaming services</a></h5>
                <h1>Drive to the Ocean</h1>
                <p>Michael Stipe</p>                
                <h5><a href="javascript:;" onClick={() => {props.onPlayAudio()}}>{props.playing ? '\u2B1C Stop' : '\u25BA Play'}</a> | <a href="https://ffm.to/drivetotheocean">STREAM</a></h5>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/message">{'\u2709'} Message</Link></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('intro')}}>Info</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('work')}}>Pathway to Paris</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Video</a></li>
                {/* <li><a href="https://ycs.michaelstipe.com" target="_blank">YCS</a></li> */}
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>More Info</a></li>
            </ul>
        </nav>
    </header>
   
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
    onPlayAudio: PropTypes.func,
    playing: PropTypes.bool
}

export default Header
