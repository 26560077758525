import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; and &#9439; 2021, Michael Stipe / Futurepicenter, Inc</p>
        <p className="copyright">Site by EK. <a href="mailto:jmssupport@murmurs.com">Support</a></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
