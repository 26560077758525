import React from 'react'
import Layout from '../components/layout'

import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'
import Video from '../components/Video'
import ReactAudioPlayer from 'react-audio-player'
import loop from '../images/outro.m4a'
import SEO from '../components/SEO'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading',
      playing: false      
    }
    this.handlePlayAudio = this.handlePlayAudio.bind(this);
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {

    
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handlePlayAudio() {
    if (!this.state.playing) {
      this.loop.audioEl.play();
      this.setState({playing: true});
    }
    else {
      this.loop.audioEl.pause();
      this.setState({playing: false});
    }
  }
  handleOpenArticle(article) {
    window.sendOwl.captureLinks();
    console.log(article);
    if (article === 'about') {
      this.loop.audioEl.pause();
      this.setState({playing: false});
    }
    
    // var video = document.getElementById("bgvideo");
    // var is_firefox = /firefox/i.test(navigator.userAgent)
    
    // if (!is_firefox) {
    //   video.style.webkitTransition = "all 0.5s ease";
    //   video.style.webkitFilter = "saturate(150%) blur(5px)";
    // }
    // else {
    //   video.pause();
    //   video.style.webkitTransition = "all 0.5s ease";
    //   video.style.webkitFilter = "saturate(150%) ";
    // }
    

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }


  handleCloseArticle() {
    // var video = document.getElementById("bgvideo");
    // var is_firefox = /firefox/i.test(navigator.userAgent);
    // if (!is_firefox) {
    //   video.style.webkitTransition = "all 0.5s ease";
    //   video.style.webkitFilter = "saturate(100%) blur(0px)";
    // }
    // else {
    //   video.play();
    //   video.style.webkitTransition = "all 0.5s ease";
    //   video.style.webkitFilter = "saturate(100%)";
    // }
    
    
    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  render() {
    
    return (
      <Layout location={this.props.location}>
         <SEO />
        <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={this.handleOpenArticle} onPlayAudio={this.handlePlayAudio} timeout={this.state.timeout} playing={this.state.playing}  />
            <ReactAudioPlayer
                src={loop}
                loop
                ref={(element) => { this.loop = element; }}
              />            
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
              playing={this.state.playing}
              loop={this.loop}
            />
            
            <Footer timeout={this.state.timeout} />
            </div>  
          <div id="bg"></div>
          {/* <div  className={`body video-background ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
            <div id="bg" className="video-foreground" >
              <video autoPlay muted loop playsInline id="loopVideo">
                <source src={loop_video} type="video/mp4" />
              </video>
              <Video />

            </div>
          </div> */}
        </div>
      </Layout>
    )
  }
}

export default IndexPage
