import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import pathway from '../images/pathway.jpeg'
import vid_title from '../images/loop_title.mp4'
import sunrise from '../images/sunrise.mp4'
import Img from 'react-image'
import cover from '../images/dtto_cover.jpg'
import portrait from '../images/portrait.png'

class Main extends React.Component {


  componentDidMount() {

  }

  ref = player => {
    this.player = player
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle();
          if (this.player) {
            this.player.getInternalPlayer().pauseVideo();
          }
          
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">OUT NOW: Michael Stipe Releases<br/>Second Solo Single<br/>“Drive To The Ocean”<br/>On Jan 4th</h2>
          <h3 className="minor">Download Purchase Benefits Pathway To Paris</h3>
          <div  align="center" className="inner"><a onMouseOver={()=>{window.sendOwl.captureLinks();}} href="https://transactions.sendowl.com/packages/746040/B0B03C37/view">Download & Donate</a></div>

          <span className="image main">

            {/* <img src={pic01} alt="" /> */}
            <div className='player-wrapper'>
              <ReactPlayer url={vid_title} playing="true" loop="true" muted="true" playsinline="true" width="100%" height="100%" className="react-player" />
            </div>
          </span>
 
          <p>            
            Michael Stipe is starting the new decade by releasing his second solo single, “Drive To The Ocean” on January 4th, 2020. The date also marks Stipe’s 60th birthday and the track will be made available exclusively via his own website - MichaelStipe.com.
          </p>
          <p align="center"  ><Img src={cover} height="300" width="300" /><br/><a className="small" href="https://download.michaelstipe.com/dtto_cover.png.zip">High Res</a></p>
          <p>
            The track will benefit <a href="https://pathwaytoparis.com">Pathway to Paris</a>, founded by Jesse Paris Smith and Rebecca Foon, with Stipe redirecting all of his earnings from sales and synchronization licensing for the first 365 days to the organization.
          </p>
          <p><strong><a href="https://pathwaytoparis.com/" target="_blank">Pathway to Paris</a></strong> is a non-profit organization dedicated to turning the Paris Agreement into reality through innovative public engagement, cultural events, supporting citizen driven initiatives and cities in
developing and implementing ambitious climate action plans.
          </p>
          <p>Pathway to Paris was founded in 2014 as a platform to bring together artists, musicians, filmmakers, poets with politicians, scientists, activists,
innovators, and leading thinkers in order to strengthen the climate movement through diverse collaborations. The organization has held major events all over the world, and has partnered with several other groups including the UNDP and 350.org. In 2017, Pathway to Paris launched their 1000 Cities Initiative for Carbon Freedom, based on the idea that if 1000 cities around the world commit to transitioning away from fossil fuels, to 100% renewable energy and zero emissions by 2040, then we can meet and go beyond the targets outlined
in the Paris Agreement.
          </p>
          <p>
            Says Stipe of this, his second solo release, "I am so happy to collaborate with Pathway To Paris for this year 2020.  The work that they are doing is vital and foundational— it is essential for a future that we can and will claim as our own.”
          </p>
          <p>
            The download is priced at 77 cents but those who prefer to give more can enter an amount of their own choosing. The download package will include exclusive wallpapers, the video, a lyric video, a high resolution audio version of the song as well as Stipe's original lyric document.
          </p>
          <p>
            “Drive To The Ocean” will be accompanied by a video directed by Stipe.
          </p>
          <p>
              Stipe’s debut solo single “<a href="https://ycs.michaelstipe.com">Your Capricious Soul</a>” was released on October 5th, with proceeds going to climate justice activists Extinction Rebellion. 
          </p>
          <p>Stipe has made a <a href="https://download.michaelstipe.com/outro_loop.mp4.zip">teaser video loop</a> and the <a href="https://download.michaelstipe.com/title_loop.mp4.zip">title loop</a> available for social sharing, with tag #drivetotheocean</p>

          
          <p align="center"  ><Img src={portrait} height="50%" width="50%" /><br/><a className="small" href="https://download.michaelstipe.com/portrait.png.zip">High Res</a></p>

          <p align="center"><strong>For more information: Chloë Walsh @ The Oriel Co.: chloe@theoriel.co</strong></p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Pathway to Paris</h2>
          <span className="image main">
            {/* <img src={pathway} alt="" /> */}
            <div className='player-wrapper'>
              <ReactPlayer url={sunrise} playing="true" loop="true" playsinline="true" muted="true" width="100%" height="100%" className="react-player" />
            </div>
          </span>
          
          <p>
          <strong><a href="https://pathwaytoparis.com/" target="_blank">PATHWAY TO PARIS</a></strong> is a non-profit organization dedicated to turning the Paris Agreement into reality through innovative public engagement, cultural events, supporting citizen driven initiatives and cities in
developing and implementing ambitious climate action plans.
          </p>
          <p>Pathway to Paris was founded in 2014 as a platform to bring together artists, musicians, filmmakers, poets with politicians, scientists, activists,
innovators, and leading thinkers in order to strengthen the climate movement through diverse collaborations. The organization has held major events all over the world, and has partnered with several other groups including the UNDP and 350.org. In 2017, Pathway to Paris launched their 1000 Cities Initiative for Carbon Freedom, based on the idea that if 1000 cities around the world commit to transitioning away from fossil fuels, to 100% renewable energy and zero emissions by 2040, then we can meet and go beyond the targets outlined
in the Paris Agreement.</p>
          <p>Michael Stipe has a long history with Pathway to Paris, performing at four events, including their first in 2014 and most recently in April, 2019 during Earthfest at the Natural History Museum in New York. "Drive to the Ocean" made its debut at the April event.</p>
          <blockquote>
            <p>“From a very young age I attached our enlightened and active progress as humans to an ability to fix our own problems. Our most noble goal is to encourage and secure sustainable energy sources; and to support–rather than lessen– Gaia, our home that we share with so many different species, life energies, and life forces.</p>
            <p>My attraction to Pathway is in seeing a younger generation approach these concerns with renewed vigor and interest, in turn inspiring each of us to deepen our commitment and resolve towards a better future for all.”</p>
            <p>– Michael Stipe</p>
          </blockquote>
          <p>
          Pathway to Paris is a 501(c)3 Non-Profit Organization Registered in the United States. 
          </p>
          <p><strong><a href="https://www.pathwaytoparis.com">www.pathwaytoparis.com</a></strong></p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Video</h2>
          <span className="image main">
            <div className='player-wrapper'>
            
              <ReactPlayer ref = {this.ref} url="https://youtu.be/luoE63wUKAE" controls={true} width="100%" height="100%" id="ytplayer" className='react-player'/>
           </div>   
          </span>
          <p>
          Video by michael stipe
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">More Info</h2>
          <p>
            Visit the "Your Capricious Soul" single <a href="https://ycs.michaelstipe.com">website.</a>
          </p>
          <p>
            <strong>Press Inquiries: </strong><br/>Chloë Walsh @ The Oriel Co.: chloe@theoriel.co
          </p>
          <p>
            <strong>Support: </strong><br/><a href="mailto:jmssupport@murmurs.com">E-Mail</a>
          </p>
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  playing: PropTypes.bool,
  loop: PropTypes.object
}

export default Main
